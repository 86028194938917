import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import Dexie from 'dexie';
import 'dexie-export-import';
import { AppDB } from 'src/app/db/db';
import { BackSyncHeader } from 'src/app/db/db';
import { HttpClient } from '@angular/common/http';
import { auth } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBService {
  private db: AppDB;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly httpClient: HttpClient
  ) {
    this.db = new AppDB();
  }

  async exportDatabase() {
    try {
      const blob = await this.db.export();
      const url = URL.createObjectURL(blob);
      const a = this.document.createElement('a');
      a.href = url;
      a.download = `${this.db.name}_backup.json`;
      this.document.body.appendChild(a);
      a.click();
      this.document.body.removeChild(a);
      URL.revokeObjectURL(url);
      console.log('Exportación completada');
    } catch (error) {
      console.error('Error exportando la base de datos:', error);
    }
  }

  async sendDatabase() {
    try {
      const blob = await this.db.export();
      const formData = new FormData();
      formData.append('file', blob, `${this.db.name}_backup.json`);

      const data = {
        file: formData,
      };

      const response = await this.httpClient
        .post<any>(`${auth.urlConfig}/file/upload/indexeddb`, data)
        .toPromise();

      console.log('Base de datos enviada al servidor:', response);
    } catch (error) {
      console.error('Error enviando la base de datos al servidor:', error);
    }
  }

  // a function to return the database as a File
  async getDatabase(): Promise<File> {
    try {
      const blob = await this.db.export();
      const file = new File([blob], `${this.db.name}_backup.json`, { type: 'application/json' });
      return file;
    } catch (error) {
      console.error('Error obteniendo la base de datos:', error);
      throw error;
    }
  }


  async importDatabase(file: File) {
    try {
      // Eliminar la base de datos antes de la importación
      await this.db.delete();
      this.db = new AppDB(); // Reinstanciar la base de datos
      const arrayBuffer = await file.arrayBuffer();
      const blob = new Blob([arrayBuffer], { type: 'application/json' });
      await this.db.import(blob);
      console.log('Importación completada');
    } catch (error) {
      console.error('Error importando la base de datos:', error);
    }
  }
}
